/*global chrome*/
import './../App.css';
import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

function App() {
  useEffect(() => {
    document.title = "Login - Inbox Enhance"
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);

  const newLogin = useGoogleLogin({
    onSuccess: codeResponse => exchangeCode(codeResponse.code),
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.send',
  });

  const exchangeCode = async (code) => {
    const response = await fetch(`https://email-master-backend.vercel.app/api/exchange-code?code=${code}`);
    const data = await response.json();
    
    let accessToken = data.result.access_token;
    let email = data.result.email;

    let tokenInfo = {
      accessToken,
      email
    }

    chrome.runtime.sendMessage("jkpmidnffikoopalnbjjojbbdnmeblfi", JSON.stringify(tokenInfo));

    setLoggedIn(true);
  }

  return (
    <div className="App">
      <header className="App-header">
        {
          loggedIn ?
            <Alert variant='success'>Success! Return to Gmail and refresh to begin using the extension</Alert>
          :
            <Button onClick={() => newLogin()} size='lg'>Sign in with Google</Button>
        }
      </header>
    </div>
  );
}

export default App;