/*global chrome*/
import { useEffect } from 'react';
import './../App.css';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

function Index() {
  useEffect(() => {
    document.title = "Inbox Enhance"
  }, []);

  return (
    <div className="App">
      <header className="App-header" style={{padding: "20px"}}>
        <h1>Inbox Enhance</h1>
        <p>Enhance your Gmail inbox today! Gain access to features such as mail merge, email open tracking and custom notes.</p>
        <p>Stop wasting time managing your inbox and use our suite of advanced email tools.</p>
        <small style={{"marginTop": "50px", "fontSize": "12px"}}>We request user data in order to identify you and send emails on for you.</small>
        {/* <Button size='lg'>Install</Button>
        <small>Note: Install button not functional until we have been approved</small> */}
        <small style={{"fontSize": "12px"}}>Inbox Enhance's use and transfer to any other app of information received from Google APIs will adhere to <a href='https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes'>Google API Services User Data Policy</a>, including the Limited Use requirements.</small>
        <small style={{"fontSize": "12px"}}><a href='/privacy'>Privacy Policy</a></small>
      </header>
    </div>
  );
}

export default Index;