import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Layout from "./pages/Layout";
import 'bootstrap/dist/css/bootstrap.min.css';
import Success from "./pages/Success";
import Cancelled from "./pages/Cancelled";
import Privacy from "./pages/Privacy";
import Index from "./pages/Index";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Index />} />
          <Route path="login" element={<Login />} />
          <Route path="success" element={<Success />} />
          <Route path="cancel" element={<Cancelled />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="*" element={<Index />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
