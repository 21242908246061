/*global chrome*/
import './../App.css';
import { Alert } from 'react-bootstrap';

function Cancel() {
  return (
    <div className="App">
      <header className="App-header">
        <Alert variant='danger'>Checkout cancelled</Alert>
      </header>
    </div>
  );
}

export default Cancel;