/*global chrome*/
import './../App.css';
import { Alert } from 'react-bootstrap';

function Success() {
  return (
    <div className="App">
      <header className="App-header">
        <Alert variant='success'>Success! Return to Gmail and refresh to begin using the extension</Alert>
      </header>
    </div>
  );
}

export default Success;